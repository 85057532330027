import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';
const footerHeight = 82;
export const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: 576,
      maxWidth: '100%',
      height: '100%',
    },
    header: {
      padding: spacing(6),
    },
    headerCloseBtn: {
      border: 'none',
    },
    headerText: {
      fontSize: 24,
      fontWeight: 500,
      color: '#101828', //TODO change after creating new palette,
    },
    content: {
      overflowY: 'auto',
      height: `calc(100vh - ${footerHeight}px)`,
    },
    contentItems: {
      padding: spacing(6, 6, 18.75),
      gap: 24,
    },
    block: {
      background: '#F7F8FA', //TODO replace after palette will be change
      padding: spacing(6),
      borderRadius: 8,
    },
    dateBlock: {
      gap: 24,
    },
    drawerOverlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    deleteBtn: {
      backgroundColor: `#D92D20 !important`, //TODO replace after creating new palette
    },
    blockTitle: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: spacing(4),
      color: '#101828', //TODO change after creating new palette,
    },
    blockSubtitle: {
      fontSize: 14,
      fontWeight: 500,
      color: '#9EA6B3', //TODO change after creating new palette
      marginBottom: spacing(2),
    },
    blockInfo: {
      gap: 24,
    },
    blockText: {
      fontSize: 14,
      fontWeight: 500,
    },
    linkIcon: {
      fontSize: 16,
      margin: spacing(0, 0, 0.5, 2),
      userSelect: 'none',
      verticalAlign: 'middle',
      fontWeight: 500,
      color: '#7E8899', //TODO replace after palette will be change
    },
    linkContainer: {
      gap: 8,
    },
    linkContent: {
      alignSelf: 'flex-start',
    },
    divider: {
      width: '100%',
      height: 1,
      backgroundColor: colors.grey.lightest,
      margin: spacing(8, 0),
    },
    detailItem: {},
    detailItemLast: {
      borderBottom: `1px solid ${colors.grey.light}`,
      paddingBottom: spacing(7),
      marginBottom: spacing(4),
    },
    feedbackContainer: {
      padding: spacing(4),
      background: '#F7F8FA', //TODO replace after palette will be change
    },
    notesText: {
      fontSize: 14,
      color: '#7E8899', //TODO replace after creating new palette
      fontWeight: 500,
    },
    emailConfirm: {
      marginLeft: spacing(2),
    },
    completeBtn: {
      marginLeft: spacing(2),
    },
    footer: {
      padding: spacing(4, 6),
      backgroundColor: colors.white.lightest,
      position: 'absolute',
      bottom: 0,
      borderTop: `1px solid #EAECF0`, //TODO change after creating new palette
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      height: footerHeight,
    },
    footerBtnContainer: {
      alignItems: 'center',
      gap: 24,

      [breakpoints.down('xs')]: {
        marginBottom: spacing(2),
      },
    },
  });
