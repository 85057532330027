import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';
const footerHeight = 82;
export const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: 576,
      maxWidth: '100%',
      height: '100%',
    },
    assigneeDraggableList: {
      maxWidth: 480,
    },
    header: {
      padding: spacing(6),
    },
    headerCloseBtn: {
      border: 'none',
    },
    headerText: {
      fontSize: 24,
      fontWeight: 500,
      color: '#101828', //TODO change after creating new palette,
    },
    content: {
      overflowY: 'auto',
      height: `calc(100vh - ${footerHeight}px)`,
    },
    contentItems: {
      padding: spacing(6, 6, 18.75),
      gap: 24,
    },
    dateContainer: {
      gap: 8,
    },
    datePicker: {
      height: '40px !important',

      '& .MuiOutlinedInput-inputAdornedStart': {
        fontSize: 14,
      },

      '& .MuiOutlinedInput-adornedStart': {
        width: '100%',
        height: '40px !important',
      },
      [breakpoints.down(768)]: {
        '& .MuiOutlinedInput-adornedStart': {
          width: '100%',
        },
      },
    },
    label: {
      lineHeight: '14px',
      marginBottom: spacing(2),
    },
    notes: {
      minHeight: 132,
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: spacing(2.5),
      '& textarea': {
        resize: 'none',
      },
    },
    timeSelector: {
      height: '40px !important',
      width: '100%',
      background: colors.white.lightest,
      '& .MuiOutlinedInput-root': {
        height: '40px !important',
      },
    },
    fullRowElement: {
      width: '100%',
    },
    rowElement: {
      width: '50%',
    },
    assigneeSelect: {
      maxWidth: 480,
    },
    assigneeErrorText: {
      color: colors.red.darkish,
      fontSize: 13,
    },
    block: {
      background: '#F7F8FA', //TODO replace after palette will be change
      padding: spacing(6),
      borderRadius: 8,
    },
    detailsContainer: {
      gap: 24,
    },
    dateBlock: {
      gap: 24,
    },
    drawerOverlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    blockTitle: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: spacing(4),
      color: '#101828', //TODO change after creating new palette,
    },
    blockSubtitle: {
      fontSize: 14,
      fontWeight: 500,
      color: '#9EA6B3', //TODO change after creating new palette
      marginBottom: spacing(2),
    },
    blockInfo: {
      gap: 24,
    },
    blockText: {
      fontSize: 14,
      fontWeight: 500,
    },
    linkContainer: {
      gap: 8,
    },
    detailItemLast: {
      borderBottom: `1px solid ${colors.grey.light}`,
      paddingBottom: spacing(7),
      marginBottom: spacing(4),
    },
    notesText: {
      fontSize: 14,
      color: '#7E8899', //TODO replace after creating new palette
      fontWeight: 500,
    },
    pickedDisabled: {
      '& .MuiOutlinedInput-adornedStart': {
        backgroundColor: `#F2F4F7 !important`, // TODO: Change after palette updated
      },
    },
    selectAdornment: {},
    selectAdornmentIcon: {
      fontSize: 15,
      marginTop: spacing(1),
      color: '#7E8899',
    },
    footer: {
      padding: spacing(4, 6),
      backgroundColor: colors.white.lightest,
      position: 'absolute',
      bottom: 0,
      borderTop: `1px solid #EAECF0`, //TODO change after creating new palette
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      height: footerHeight,
    },
    footerBtnContainer: {
      alignItems: 'center',
      gap: 24,

      [breakpoints.down('xs')]: {
        marginBottom: spacing(2),
      },
    },
  });
