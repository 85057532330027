import { Drawer, InputAdornment } from '@material-ui/core';
import { AccessTime as ClockIcon } from '@material-ui/icons';
import cx from 'classnames';
import moment from 'moment';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { useTasksShowQuery, useTasksUpdateMutation } from '@apis/tasksApi';
import { TaskShowItem, UpdateTaskPayload } from '@apis/tasksApi.types';
import { useUsersListQuery } from '@apis/usersApi';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { DatePicker, MaterialDate } from '@shared/components/date-picker';
import { DrawerHeader } from '@shared/components/drawer-header';
import { Flex } from '@shared/components/flex';
import { GroupedOption } from '@shared/components/group-select';
import { MultiGroupSelect } from '@shared/components/group-select/multi-group-select';
import { Loading } from '@shared/components/loading';
import { Option } from '@shared/components/select/Select.types';
import { TextField } from '@shared/components/text-field';
import { TimeSelector } from '@shared/components/time-selector';
import { drawerTransition } from '@shared/constants/drawer';
import { formatDate } from '@shared/utils/date';

import { styles } from './ViewingsEditDrawer.styles';

export interface ViewState {
  date: MaterialDate | Date;
  title: string;
  time: string;
  notes: string;
  agents: Id[];
}

type ViewStateKey = keyof ViewState;

const initialState: ViewState = {
  date: moment().format('YYYY-MM-DD'),
  title: '',
  time: '00:00',
  notes: '',
  agents: [],
};

export interface ViewingsEditDrawerProps extends WithStyles<typeof styles> {
  viewId: string;
  open: boolean;
  onClose: () => void;
  onDetailsOpen: (view: TaskShowItem) => void;
}

const ViewingsEditDrawerComponent: FC<ViewingsEditDrawerProps> = ({
  classes,
  viewId,
  open,
  onClose,
  onDetailsOpen,
}) => {
  const [state, setState] = useState(initialState);
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [errors, setErrors] = useState<ItemString>({});

  const tasksShowQuery = useTasksShowQuery(viewId);

  const usersListQuery = useUsersListQuery({ query: { limit: '-1', order: 'surname-asc' } }, { enabled: !!viewId });

  const updateTaskPayload: UpdateTaskPayload = useMemo(() => {
    const dateFrom = `${formatDate(state.date, 'YYYY-MM-DD', false)} ${state.time}:00`;
    const dateTo = formatDate(moment(dateFrom).add(30, 'minutes'), 'YYYY-MM-DD HH:mm:ss', false);

    return {
      body: {
        end: dateTo,
        notes: state.notes,
        start: dateFrom,
        title: state.title,
        users: state.agents.join(','),
      },
      id: viewId,
    };
  }, [state]);

  const validate = (inputsData: ViewState) => {
    const _errors: ItemString = {};
    if (!inputsData.title?.trim()) {
      _errors.title = t('xtextx_is_required', { text: t('title') });
    }
    if (!inputsData.date) {
      _errors.date = t('xtextx_is_required', { text: t('date') });
    }

    if (!inputsData.agents.length) {
      _errors.users = t('xtextx_is_required', { text: t('users') });
    }

    setErrors(_errors);
    return !Object.keys(_errors).length;
  };
  const handleClose = () => {
    if (tasksShowQuery.data) {
      onDetailsOpen(tasksShowQuery.data);
    }
    onClose();
  };

  const tasksUpdateMutation = useTasksUpdateMutation({ onSuccess: handleClose });

  useEffect(() => {
    if (submitAttempted) {
      validate(state);
    }
  }, [state]);

  useEffect(() => {
    if (tasksShowQuery.data) {
      setState((_prev) => ({
        ..._prev,
        date: moment(tasksShowQuery.data.from),
        title: tasksShowQuery.data.title,
        time: formatDate(tasksShowQuery.data.from, 'hh:mm'),
        notes: tasksShowQuery.data.notes,
        agents: tasksShowQuery.data.users.map((el) => el.id),
      }));
    }
  }, [open, tasksShowQuery.data]);

  const handleUpdate = () => {
    setSubmitAttempted(true);
    if (!validate(state)) {
      return;
    }

    tasksUpdateMutation.mutate(updateTaskPayload);
  };

  const handleDetailsOpen = () => {
    handleClose();
    if (tasksShowQuery.data) {
      onDetailsOpen(tasksShowQuery.data);
    }
  };

  const handleDateDateChange = (dateValue: MaterialDate) => {
    setState((_prevEventData) => ({
      ..._prevEventData,
      date: dateValue,
    }));
  };

  const handleTimeSelectChange = (value: string) => {
    setState((_prevEventData) => ({
      ..._prevEventData,
      time: value,
    }));
  };

  const assignedUsersOptions: GroupedOption[] = useMemo(() => {
    if (!usersListQuery.data?.data) {
      return [];
    }
    return [
      {
        label: '',
        options: usersListQuery.data.data.map(
          (user) =>
            ({
              id: user.id,
              label: user.name,
            } as Option)
        ),
      },
    ];
  }, [usersListQuery.data?.data]);

  const handleChangeInputs = (type: ViewStateKey) => (e: ChangeEvent<HTMLInputElement>) =>
    setState((_prev) => ({ ..._prev, [type]: e.target.value }));

  const handleAssigneeSelect = (ids: Id[]) => {
    setState((_prev) => ({
      ..._prev,
      agents: ids,
    }));
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setState(initialState);
        setSubmitAttempted(false);
        setErrors({});
      }, drawerTransition);
    }
  }, [open]);

  const disabledFields = useMemo(() => tasksShowQuery.isFetching, [tasksShowQuery.isFetching]);

  const content = useMemo(() => {
    if (tasksShowQuery.isLoading) {
      return <Loading />;
    }

    return (
      <>
        <div className={classes.content}>
          <Flex direction="column" classes={{ root: classes.contentItems }}>
            <Flex direction="column" classes={{ root: classes.block }}>
              <span className={classes.blockTitle}>{t('viewing_details')}</span>
              <Flex classes={{ root: classes.detailsContainer }}>
                <Flex autoWidth={false} classes={{ root: classes.fullRowElement }}>
                  <span className={classes.label}>{t('xtextx_asterisk', { text: t('title') })}</span>
                  <TextField
                    fullWidth
                    disabled={disabledFields}
                    value={state.title}
                    onChange={handleChangeInputs('title')}
                    error={!!errors?.title}
                    errorText={errors?.title}
                    placeholder={t('title')}
                  />
                </Flex>
                <Flex autoWidth={false} direction="column">
                  <span className={classes.label}>{t('xtextx_asterisk', { text: t('when') })}</span>
                  <Flex autoWidth={false} justifyContent="space-between" classes={{ root: classes.dateContainer }}>
                    <DatePicker
                      classes={{
                        root: classes.rowElement,
                        picker: classes.datePicker,
                      }}
                      dateFormat="DD/MM/YYYY"
                      disabled={disabledFields}
                      error={!!errors.date}
                      errorText={errors.date}
                      onChange={handleDateDateChange}
                      placeholder="Select"
                      value={state.date}
                    />
                    <TimeSelector
                      time={state.time}
                      onTimeChange={handleTimeSelectChange}
                      disabled={disabledFields}
                      classes={{
                        root: cx(classes.timeSelector, { [classes.pickedDisabled]: disabledFields }),
                        container: classes.rowElement,
                      }}
                      startAdornment={
                        <InputAdornment className={classes.selectAdornment} position="start">
                          <ClockIcon classes={{ root: classes.selectAdornmentIcon }} />
                        </InputAdornment>
                      }
                    />
                  </Flex>
                </Flex>
                <Flex autoWidth={false}>
                  <span className={classes.label}>{t('notes')}</span>
                  <TextField
                    disabled={disabledFields}
                    value={state.notes}
                    onChange={handleChangeInputs('notes')}
                    placeholder={t('additional_notes_for_this_view')}
                    fullWidth
                    multiline={true}
                    classes={{ input: classes.notes }}
                  />
                </Flex>
                <Flex direction="column" autoWidth={false} classes={{ root: cx(classes.fullRowElement) }}>
                  <span className={classes.label}>{t('assigned_to')}</span>
                  <MultiGroupSelect
                    disabled={disabledFields}
                    error={!!errors?.users}
                    errorText={errors?.users}
                    placeholder={t('xtextx_ellipsis', { text: t('please_select') })}
                    value={state.agents}
                    groupOptions={assignedUsersOptions}
                    onChange={handleAssigneeSelect}
                    classes={{ root: classes.assigneeSelect, errorText: classes.assigneeErrorText }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </div>
        <div className={classes.footer}>
          <Flex wrap="nowrap" classes={{ root: classes.footerBtnContainer }}>
            <Button
              text={t('cancel')}
              variant="outlined"
              color="default"
              disabled={disabledFields}
              loading={disabledFields}
              onClick={handleDetailsOpen}
            />
          </Flex>
          <Flex wrap="nowrap" classes={{ root: classes.footerBtnContainer }}>
            <Button text={t('save')} onClick={handleUpdate} loading={disabledFields} />
          </Flex>
        </div>
      </>
    );
  }, [tasksShowQuery.isLoading, state, errors, disabledFields, assignedUsersOptions, handleDetailsOpen, handleUpdate]);
  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      transitionDuration={drawerTransition}
      classes={{ paper: classes.root }}
    >
      <DrawerHeader
        onClose={handleClose}
        header={t('edit_viewing')}
        classes={{
          header: classes.header,
          headerBtn: classes.headerCloseBtn,
          headerText: classes.headerText,
        }}
      />
      {content}
    </Drawer>
  );
};
export const ViewingsEditDrawer = withStyles(styles)(ViewingsEditDrawerComponent);
