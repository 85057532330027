import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';
const footerHeight = 82;
export const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: 576,
      maxWidth: '100%',
      height: '100%',
    },
    assigneeDraggableList: {
      maxWidth: 480,
    },
    header: {
      padding: spacing(6),
    },
    headerCloseBtn: {
      border: 'none',
    },
    headerText: {
      fontSize: 24,
      fontWeight: 500,
      color: '#101828', //TODO change after creating new palette,
    },
    content: {
      overflowY: 'auto',
      height: `calc(100vh - ${footerHeight}px)`,
    },
    contentItems: {
      padding: spacing(6, 6, 18.75),
      gap: 24,
    },
    radios: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    radiosFormControl: {
      margin: spacing(2, 1, 0, 0),
    },
    label: {
      lineHeight: '14px',
      marginBottom: spacing(2),
      display: 'inline-block',
      fontWeight: 500,
    },
    notes: {
      minHeight: 132,
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: spacing(2.5),
      '& textarea': {
        resize: 'none',
      },
    },
    fullRowElement: {
      width: '100%',
    },
    rowElement: {
      width: '50%',
    },
    block: {
      background: '#F7F8FA', //TODO replace after palette will be change
      padding: spacing(6),
      borderRadius: 8,
      gap: 24,
    },
    drawerOverlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    blockTitle: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: spacing(4),
      color: '#101828', //TODO change after creating new palette,
    },
    blockSubtitle: {
      fontSize: 14,
      fontWeight: 500,
      color: '#9EA6B3', //TODO change after creating new palette
      marginBottom: spacing(2),
    },
    blockInfo: {
      gap: 24,
    },
    blockText: {
      fontSize: 14,
      fontWeight: 500,
    },
    linkContainer: {
      gap: 8,
    },
    notesText: {
      fontSize: 14,
      color: '#7E8899', //TODO replace after creating new palette
      fontWeight: 500,
    },
    footer: {
      padding: spacing(4, 6),
      backgroundColor: colors.white.lightest,
      position: 'absolute',
      bottom: 0,
      borderTop: `1px solid #EAECF0`, //TODO change after creating new palette
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      height: footerHeight,
    },
    footerBtnContainer: {
      alignItems: 'center',
      gap: 24,

      [breakpoints.down('xs')]: {
        marginBottom: spacing(2),
      },
    },
  });
